<script lang="ts" setup>
const consumerAuth = useConsumerAuthStore()
const joinBreakoutRoom = computed(() => consumerAuth.joinBreakoutRoom)
const teamName = computed(() => consumerAuth.dataPackage?.breakoutRoom?.breakout_room_name)
const isEnded = computed(() => consumerAuth.isEnded)
const playerCurrentTime = computed(() => consumerAuth.playerCurrentTime)

// Add these to handle the transition
const isRoomChanged = ref(false)
const previousRoom = ref(false)

// Watch for room changes and trigger the transition
watch(joinBreakoutRoom, (newRoom) => {
  if (previousRoom.value !== newRoom) {
    isRoomChanged.value = true
    setTimeout(() => {
      isRoomChanged.value = false
    }, 1000) // Reset after 1 second
  }
  previousRoom.value = newRoom
})

const shouldShowRoomStatus = computed(() => {
  return !isEnded.value && playerCurrentTime.value > 0
})
</script>

<template>
  <header class="bg-white shadow-md py-4 px-6" @contextmenu.prevent>
    <div class="max-w-7xl mx-auto grid grid-cols-3 items-center">
      <div class="flex items-center">
        <NuxtLink class="text-2xl font-bold text-purple-900">SYNERGOS</NuxtLink>
      </div>

      <div v-if="shouldShowRoomStatus" class="flex items-center justify-center">
        <div :class="[
          'px-4 py-2 rounded-full transition-all duration-1000',
          isRoomChanged ? 'bg-purple-600 text-white scale-110' : 'bg-purple-100 text-purple-700'
        ]">
          {{ joinBreakoutRoom ? 'You are now in your team room' : 'You are in the main room' }}
        </div>
      </div>

      <div class="flex items-center justify-end">
        <div v-if="teamName && shouldShowRoomStatus" class="text-lg text-purple-700">
          Your team is <span class="font-semibold text-purple-900">{{ teamName }}</span>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.router-link-active {
  @apply text-purple-900 font-semibold;
}
</style>